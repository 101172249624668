<template>
  <div class="scs-basket">
    <template v-if="!loading">
      <template v-if="basketItems.length > 0">
        <div class="scs-basket__content">
          <template v-for="(item, index) in basketItems">
            <template v-if="item.price > 0">
              <div class="scs-basket-item" :key="index">
                <div class="scs-basket-item__name">{{ item.name }}</div>

                <!-- <div class="scs-basket-item__count">
                  <Quantity
                    v-model="basketStorage[index].count"
                    @input="changeCount()"
                  />
                </div> -->

                <div class="scs-basket-item__price">
                  <template v-if="item.weekend">
                    {{ item.priceWeekend * item.count }} руб.
                  </template>

                  <template v-else-if="item.monday">
                    {{ item.priceMonday * item.count }} руб.
                  </template>

                  <template v-else>
                    {{ item.price * item.count }} руб.
                  </template>
                </div>

                <div class="scs-basket-item__btn">
                  <a
                    href="#"
                    class="scs-delete"
                    @click.prevent="deletItem(index)"
                  ></a>
                </div>
              </div>
            </template>
          </template>
        </div>

        <div class="row mt-4">
          <div class="col-6">
            <a
              href="#"
              @click.prevent="close()"
              class="scs-btn scs-btn--transparent"
            >Продолжить покупки</a>
          </div>

          <div class="col-6">
            <a
              href="#basket-big"
              data-fancybox=""
              class="scs-btn popup"
            >В корзину</a>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="text-center">Корзина пуста</div>
      </template>
    </template>

    <template v-else>
      <Preloader />
    </template>
  </div>
</template>

<script>
import axios from 'axios';
// import Quantity from '@/components/Quantity.vue';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'Basket',
  components: {
    // Quantity,
    Preloader,
  },
  data() {
    return {
      basketStorage: [],
      productsList: [],
      saleList: [],
      loading: false,
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    productsId() {
      return this.basketStorage.map((item) => item.id);
    },
    basketItems() {
      return this.basketStorage.map((itemStorage) => {
        const item = { ...itemStorage };

        const findProduct = this.productsList.find((itemProduct) => itemProduct.id === item.id);
        if (findProduct) {
          item.name = findProduct.name;
          item.price = findProduct.price;
          item.priceWeekend = findProduct.priceWeekend;
          item.priceMonday = findProduct.priceMonday;
          item.priceBeforeSale = findProduct.priceBeforeSale;
          item.saleId = findProduct.saleId;
        } else {
          item.price = 0;
        }

        return item;
      });
    },
    saleSelectTicket() {
      return this.basketItems.reduce((total, item) => {
        const saleSelectTicket = total;
        const { saleId, count } = item;
        const saleFindIndex = saleSelectTicket.findIndex((sale) => sale.saleId === saleId);

        if (saleFindIndex !== -1) {
          saleSelectTicket[saleFindIndex].count += count;
        } else {
          saleSelectTicket.push({
            saleId,
            count,
          });
        }

        return saleSelectTicket;
      }, []);
    },
    checkSale() {
      return this.saleSelectTicket.map((item) => {
        const sale = this.saleList.find((saleItem) => saleItem.id === item.saleId);

        return {
          saleId: item.saleId,
          check: (sale && sale.count <= item.count),
          sale: (sale) ? sale.sale : 0,
        };
      });
    },
  },
  methods: {
    getBasketItem(items) {
      if (this.productsId.length > 0) {
        this.loading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'getBasketItem');
        url.searchParams.set('arItemsId', JSON.stringify(items));

        this.productsList = [];
        this.saleList = [];

        axios
          .get(url)
          .then((response) => {
            this.productsList = response.data.data.tickets;
            this.saleList = response.data.data.saleList;

            this.setSale();

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getBasketStorage() {
      this.basketStorage = (JSON.parse(localStorage.getItem('basket'))) || [];
      this.changeBasketCountElem();
    },
    changeBasketCountElem() {
      const elems = document.getElementsByClassName('basket__count');
      for (let i = 0; i < elems.length; i += 1) {
        elems[i].textContent = this.basketStorage.length;
      }
    },
    deletItem(index) {
      this.basketStorage.splice(index, 1);

      localStorage.setItem('basket', JSON.stringify(this.basketStorage));
      window.dispatchEvent(new CustomEvent('updateBasket', {
        detail: JSON.parse(localStorage.getItem('basket')),
      }));
    },
    changeCount() {
      localStorage.setItem('basket', JSON.stringify(this.basketStorage));
      window.dispatchEvent(new CustomEvent('updateBasket', {
        detail: JSON.parse(localStorage.getItem('basket')),
      }));
    },
    onStorageUpdate(event) {
      this.basketStorage = (event.detail) || [];
      this.getBasketItem(this.productsId);
      this.changeBasketCountElem();
    },
    close() {
      window.jQuery('.icon__item').click();
    },
    setSale() {
      this.productsList = this.productsList.map((item) => {
        const ticket = item;

        if (ticket.saleId) {
          const sale = this.checkSale.find((saleItem) => saleItem.saleId === ticket.saleId);

          if (sale && sale.check) {
            let newPrice = ticket.priceBeforeSale - ticket.priceBeforeSale * (sale.sale / 100);
            newPrice = Math.floor(newPrice * 100) / 100;

            ticket.price = newPrice;
          } else {
            ticket.price = ticket.priceBeforeSale;
          }
        }

        return ticket;
      });
    },
  },
  created() {
    window.addEventListener('updateBasket', this.onStorageUpdate);

    this.getBasketStorage();
    this.getBasketItem(this.productsId);
  },
};
</script>

<style lang="scss" scoped>
  .scs-basket {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
    text-transform: initial;

    &__content {
      max-height: 230px;
      overflow: auto;
    }

    &-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #7DB1AD;
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:last-child {
        border-bottom: none;
      }

      &__name {
        margin-right: auto;

        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 15px;
        }
      }

      &__count {
        flex-shrink: 0;
        margin-left: 15px;

        @media (max-width: 767px) {
          margin-left: 0;
        }
      }

      &__price {
        flex-shrink: 0;
        margin-left: 15px;
        width: 92px;

        @media (max-width: 767px) {
          width: auto;
          order: 1;
        }
      }

      &__btn {
        width: 19px;
        margin-left: 15px;
        flex-shrink: 0;
      }

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
  }

  .scs-delete {
    width: 19px;
    height: 19px;
    display: block;
    background-image: url(~@/assets/img/delete.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  .scs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #F39712;
    border-radius: 7px;
    height: 45px;
    padding: 0 5px;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    &:hover {
      background: #f1b358;
    }

    &--transparent {
      background: transparent;
      border: 2px solid #FFFFFF;

      &:hover {
        background-color: #FFFFFF;
        color: #7DB1AD;
      }
    }

    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
</style>
