<template>
  <Basket />
</template>

<script>
import Basket from '@/components/Basket.vue';

export default {
  name: 'App',
  components: {
    Basket,
  },
  data() {
    return {
      api: {
        path: '/bitrix/services/main/ajax.php',
        components: 'seoprotor:api',
        mode: 'class',
      },
    };
  },
  methods: {
    getApiUrl() {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${this.api.path}`);
      url.searchParams.set('c', this.api.components);
      url.searchParams.set('mode', this.api.mode);

      return url.toString();
    },
    clearBasket() {
      localStorage.setItem('basket', JSON.stringify([]));
      window.dispatchEvent(new CustomEvent('updateBasket', {
        detail: JSON.parse(localStorage.getItem('basket')),
      }));
    },
  },
  created() {
    const apiUrl = this.getApiUrl();
    this.$store.dispatch('setApiUrl', apiUrl);

    this.clearBasket();
  },
};
</script>

<style lang="scss">
  .basket__content {
    width: 452px;
    height: auto;
    transform: translateY(calc(-100% - 100px));
    transition: transform .25s ease-in-out, opacity .25s ease-in-out;

    &_active {
      transform: translateY(0);
    }

    @media (max-width: 767px) {
      width: 510px;
      right: -70px;
    }

    @media (max-width: 575px) {
      position: fixed;
      width: 100%;
      right: 0;
    }
  }

  .basket__inner:before {

    @media (max-width: 767px) {
      right: 60px;
    }

    @media (max-width: 575px) {
      right: 24.5%;
    }
  }
</style>
