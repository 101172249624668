// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/delete.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".scs-basket[data-v-22e93b3e]{color:#fff;font-weight:700;font-size:16px;text-transform:none}.scs-basket__content[data-v-22e93b3e]{max-height:230px;overflow:auto}.scs-basket-item[data-v-22e93b3e]{display:flex;align-items:center;border-bottom:1px solid #7db1ad;padding-bottom:15px;margin-bottom:15px}.scs-basket-item[data-v-22e93b3e]:last-child{border-bottom:none}.scs-basket-item__name[data-v-22e93b3e]{margin-right:auto}@media(max-width:767px){.scs-basket-item__name[data-v-22e93b3e]{width:100%;margin-bottom:15px}}.scs-basket-item__count[data-v-22e93b3e]{flex-shrink:0;margin-left:15px}@media(max-width:767px){.scs-basket-item__count[data-v-22e93b3e]{margin-left:0}}.scs-basket-item__price[data-v-22e93b3e]{flex-shrink:0;margin-left:15px;width:92px}@media(max-width:767px){.scs-basket-item__price[data-v-22e93b3e]{width:auto;order:1}}.scs-basket-item__btn[data-v-22e93b3e]{width:19px;margin-left:15px;flex-shrink:0}@media(max-width:767px){.scs-basket-item[data-v-22e93b3e]{flex-wrap:wrap}}.scs-delete[data-v-22e93b3e]{width:19px;height:19px;display:block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.scs-btn[data-v-22e93b3e]{display:flex;align-items:center;justify-content:center;font-weight:700;font-size:15px;line-height:18px;text-align:center;letter-spacing:.1em;text-transform:uppercase;color:#fff;background:#f39712;border-radius:7px;height:45px;padding:0 5px;transition:background-color .25s ease-in-out,color .25s ease-in-out}.scs-btn[data-v-22e93b3e]:hover{background:#f1b358}.scs-btn--transparent[data-v-22e93b3e]{background:transparent;border:2px solid #fff}.scs-btn--transparent[data-v-22e93b3e]:hover{background-color:#fff;color:#7db1ad}@media(max-width:575px){.scs-btn[data-v-22e93b3e]{font-size:12px;line-height:18px}}", ""]);
// Exports
module.exports = exports;
